body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: monserrat, serif;
  src: local("Montserrat"), url("./fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: 'Tropiline';
  src: local("Tropiline"), url("./fonts/Tropiline-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: 'Tropiline';
  src: local("Tropiline"), url("./fonts/Tropiline-Black.otf") format("opentype");
  font-weight: 900;
}

@font-face {
  font-family: 'Tropiline';
  src: local("Tropiline"), url("./fonts/Tropiline.otf") format("opentype");
  /* font-weight: 400; */
}


/*@font-face {
  font-family: gramatika, serif;
  font-style: normal;
  font-weight: 400;
  src: local("GramatikaRegular"),
    url("./fonts/Gramatika Regular.otf") format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: gramatika, serif;
  font-style: normal;
  font-weight: 600;
  src: local("GramatikaBold");
}*/

/*@font-face {
  font-family: gramatika, sans-serif;
  font-style: normal;
  font-weight: 500;
  src: local("Gramatika");
}*/

/* @font-face {
  font-family: "Neue Haas Grotesk Bold";
  src: local("Neue Haas Grotesk Bold"),
   url("./fonts/38184073029.ttf") format("truetype");
  font-weight: bold;
} */
